<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>款式信息（SPU）</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form" size="default">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="SPU编号" prop="productCode">
          <el-col style="width: 120px">
            <el-input v-model="productCode" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="Search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="Plus" @click="add()">
        新增
      </el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:productImg="scope">
        <el-image style="height: 32px; width: 32px" lazy :src="apiUrl + 'static/code/' + scope.row.productCode + '?t=0'"
          :preview-src-list="[apiUrl + 'static/code/' + scope.row.productCode + '?t=1']">
          <template #error>
            <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
          </template>
        </el-image>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" type="primary" link size="small">
          [编辑]
        </el-button>
        <el-button @click="del(scope.row)" type="primary" link size="small">
          [删除]
        </el-button>
      </template>
    </static-table>

    <static-dialog ref="dialog" class="dialog" title="款式信息维护SPU" :opened="opened" :ok="submit">
      <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="款式大类" prop="category">
              <el-select v-model="forms.category" placeholder="请选择" :disabled="forms.id != null"
                @change="chooseOption($event, dict.categorys)">
                <el-option v-for="item in dict.categorys" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="SPU编号" prop="productCode">
              <el-input v-model="forms.productCode" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
            <el-form-item label="款式名称" prop="productName" style="max-width: 95%">
              <el-input v-model="forms.productName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-input v-model="forms.serialCode" autocomplete="off" placeholder="自定义款号"></el-input>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="规格" prop="productUnit">
              <el-input v-model="forms.productUnit" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="参考质量" prop="productMass">
              <el-input v-model="forms.productMass" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="款式标签" prop="productTags">
              <el-input v-model="forms.productTags" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="图片上传" prop="productTags">
              <el-upload :action="apiUrl + 'BaseUploader/Basic?dataType=Product&dataID=' + forms.id + '&fileCode=' + forms.productCode"
                list-type="picture-card" :on-success="uploadHandleSuccess" :on-remove="uploadHandleRemove"
                :before-upload="uploadHandleBefore" :file-list="fileList">
                <el-icon>
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="描述" prop="productDesc" style="max-width: 95%">
              <el-input type="textarea" v-model="forms.productMass" :rows="2" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="促销语" prop="promotionDesc" style="max-width: 95%">
              <el-input type="textarea" v-model="forms.promotionDesc" :rows="2" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="所属品牌" prop="brandName">
              <el-select v-model="forms.brandName" filterable placeholder="请选择">
                <el-option v-for="item in dict.brands" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌款号" prop="brandProductCode">
              <el-input v-model="forms.brandProductCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="款式工厂" prop="factoryName">
              <el-select v-model="forms.factoryName" filterable placeholder="请选择">
                <el-option v-for="item in dict.factorys" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="工厂款号" prop="factoryProductCode">
              <el-input v-model="forms.factoryProductCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="采购参考价" prop="purchasePrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.purchasePrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="售卖价" prop="sellPrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.sellPrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </static-dialog>
  </el-card>
</template>
<script>
import { fetchFileList, delFile } from "@/api/basic";
import {
  fetchSpuList,
  fetchSpuDetail,
  submitSpu,
  delSpu,
  fetchBillCode,
  getPrevID
} from "@/api/product";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "SpuList",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      apiUrl: '',
      productCode: "",

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { prop: "category", label: "大类名称", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "productCode", label: "SPU编号", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "serialCode", label: "自定编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "template", prop: "productImg", label: "图片", width: "70", },
          { prop: "productName", label: "SPU名称", sortable: true, searchable: true, width: "160", showOverflowTooltip: true },
          { prop: "productUnit", label: "款式规格", sortable: true, searchable: true, width: "100", showOverflowTooltip: true, },
          { prop: "productMass", label: "质量", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "productTags", label: "标签", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "brandName", label: "品牌名称", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "brandProductCode", label: "品牌SPU", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "factoryName", label: "工厂名", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "factoryProductCode", label: "工厂SPU", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "purchasePrice", label: "采购价", sortable: true, width: "80", align: "right", },
          { prop: "sellPrice", label: "售价", sortable: true, width: "80", align: "right", },
          { prop: "createDate", label: "创建时间", sortable: true, width: "140", showOverflowTooltip: true, },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "100", },
          { type: "toolbar", label: "操作", width: "120" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      centerDialogVisible: false,
      dialogWidth: "30%",

      dict: {
        categorys: [],
        brands: [],
        factorys: [],
      },
      forms: {},
      labelWidth: "110px",
      rules: {
        category: [
          { required: true, message: "请选择款式大类", trigger: "blur" },
        ],
        productName: [
          { required: true, message: "请输入款式名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1-30之间", trigger: "blur" },
        ],
        // purchasePrice: [
        //   { required: true, message: "请输入采购参考价格", trigger: "blur" },
        // ],
        // sellPrice: [
        //   { required: true, message: "请输入售卖预估价格", trigger: "blur" },
        // ],
        brandName: [
          { required: true, message: "请选择所属品牌", trigger: "blur" },
        ],
        factoryName: [
          { required: true, message: "请选择对应工厂", trigger: "blur" },
        ],
      },
      //fileList:[{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]
      fileList: []
    };
  },
  mounted() {
    this.fetchs();

    this.$kaung.dictionary("stock", "category").then((res) => {
      this.dict.categorys = res;
    });
    this.$kaung.dictionary("stock", "brand").then((res) => {
      this.dict.brands = res;
    });
    this.$kaung.dictionary("stock", "factory").then((res) => {
      this.dict.factorys = res;
    });

    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  methods: {
    add() {
      this.currRow = {};
      this.$refs.dialog.toggle();
    },
    edit(row) {
      this.currRow = row;
      this.$refs.dialog.toggle();
    },
    del(row) {
      this.currRow = row;
      let that = this;

      this.$confirm("此操作将删除本行数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSpu(that.currRow.id).then((res) => {
          that.$message({
            type: res.type,
            message: res.content,
          });
          that.fetchs();
        });
      });
    },
    chooseOption(value, options) {
      let items = options.filter(function (item) {
        return item.value == value;
      });
      if (items && items.length > 0) {
        let that = this;
        fetchBillCode(items[0].attribute || "Z").then((res) => {
          that.forms.productCode = res.data;

          getPrevID().then((res) => {
            that.forms.id = res;
          });
        });
      }
    },
    opened() {
      let that = this;
      this.forms = {};
      if (this.currRow && this.currRow.id) {
        fetchSpuDetail(this.currRow.id).then((res) => {
          that.forms = { ...res.data };
        });

        fetchFileList(this.currRow.id, this.currRow.productCode).then((res) => {
          that.fileList = [];
          res.data.forEach(function (item) {
            that.fileList.push({ id: item.id, name: item.name, url: that.apiUrl.substr(0, that.apiUrl.length - 1) + item.path });
          })
        });
      }
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        let chooseBrands = that.dict.brands.filter(function (item) {
          return item.value == that.forms.brandName;
        });
        let chooseFactorys = that.dict.factorys.filter(function (item) {
          return item.value == that.forms.factoryName;
        });
        if (chooseBrands.length == 0 || chooseFactorys.length == 0) {
          that.$message({
            message: "请重新选择工厂和品牌",
          });
          return;
        }

        that.forms.brandCode = chooseBrands[0].name;
        that.forms.factoryCode = chooseFactorys[0].name;
        submitSpu((that.currRow.id || ''), that.forms).then((res) => {
          that.$message({
            type: res.type,
            message: res.content,
          });

          if (res.data) {
            that.fetchs();
            that.$refs.dialog.toggle();
          }
        });
      });
    },
    fetchs() {
      this.querys.filters = [];
      if (this.productCode)
        this.querys.filters.push({
          name: "productCode",
          value: "%" + this.productCode.trim() + "%",
          operate: 5,
        });
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchSpuList(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    //控制dialog宽度与子组件控制关闭弹窗
    getdialogWidth(data) {
      if (data === "80%" || data === "30%") {
        this.dialogWidth = data;
      } else {
        this.centerDialogVisible = false;
      }
    },

    uploadHandleBefore(file) {
      console.log(file)
      if (!this.forms.id || !this.forms.productCode)
        return false;
    },
    uploadHandleRemove(file, fileList) {
      console.log(file, fileList)
      delFile(file.id).then((res) => {
        that.$message({
          type: res.type,
          message: res.content,
        });
      });
    },
    uploadHandleSuccess(res, file, fileList) {
      console.log(res, file, fileList)
      this.$message({
        type: res.type,
        message: res.content,
      });

      if (res.code != 200) {
        fileList = fileList.pop()
      }
    },
  },
};
</script>
<style lang="less">
.xlsdialog {
  .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.el-form .el-date-editor {
  width: 220px;
}</style>>

